.footer {
  background-color: #1d1d1d;
  padding-top: 2rem;
  padding-bottom: 11rem;
}

.title {
  color: #fff;
}

.description {
  color: #fff;
}

.footerlink,
.footerlink:hover {
  color: rgb(255, 255, 255);
}
